<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">Weather</p>
    </header>
    <div class="card-content">
      <div class="content">
        <apexchart
          type="line"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment/src/moment";
import { setupCache } from "axios-cache-adapter";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

export default {
  name: "WeatherCard",
  props: {},
  data() {
    return {
      lastUpdated: new Date(),
      chartOptions: {
        chart: {
          id: "weather-chart",
          fontFamily: "Poppins",
          toolbar: {
            show: false,
          },
        },
        colors: ["#000000", "#808080"],
        xaxis: {
          categories: [],
          type: "datetime",
          labels: { format: "ddd dd" },
        },
        dataLabels: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
        stroke: {
          width: 2,
          curve: "smooth",
          dashArray: [0, 4],
        },
      },
      series: [
        {
          name: "High",
          data: [],
        },
        {
          name: "Low",
          data: [],
        },
      ],
    };
  },
  methods: {
    mountedSignal() {
      //emitted event back to the parent
      this.$emit("mounted");
    },
  },
  filters: {
    moment(date) {
      return moment(date).startOf("hour").fromNow();
    },
  },
  mounted() {
    const payload = { lat: "52.5377384", lng: "13.3991115" };
    var path =
      "https://api.intra.io/api/v1/weather?lat=52.5377384&lng=13.3991115";
    const config = {

      method: "GET",
    };
    api
      .get(path, config)
      .then((response) => {
        let highs = [];
        let lows = [];
        let dates = [];
        let responseData = response.data.data;
        let daily = responseData.daily.data;
        for (let day of daily.slice(0, 5)) {
          var utcSeconds = day.time;
          let datetimeObj = new Date(0);
          dates.push(datetimeObj.setUTCSeconds(utcSeconds));
          highs.push(day.temperatureHigh.toFixed(0));
          lows.push(day.temperatureLow.toFixed(0));
        }
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            xaxis: {
              categories: dates,
            },
          },
        };
        this.series = [{ data: highs }, { data: lows }];
      })
      .catch((error) => {
        console.log(error);
        this.type = "notification is-danger";
      });
  },
  filters: {
    moment(date) {
      return moment.unix(date).format("ddd");
    },
    momentFromNow(date) {
      return moment.utc(date).fromNow();
    },
  },
};
</script>


<style>
</style>
