<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">Forecast</p>
    </header>
    <div class="card-content">
      <div class="content">
        <p id="primary" class="">
          {{ currentTemperature.toFixed(0)
          }}<span id="third">℃ {{ summary }}</span>
        </p>
        <p id="secondary" class="">
          {{ rain }} % <span id="third"> chance of rain</span>
        </p>

        <table class="">
          <thead>
            <tr>
              <th></th>
              <th>h</th>
              <th>l</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(day, index) in daily.slice(0, 3)" :key="index">
              <td class="" id="secondary">{{ day.time | moment }}</td>
              <td class="" id="secondary">
                {{ day.temperatureHigh.toFixed(0) }}
              </td>
              <td class="" id="secondary">
                {{ day.temperatureLow.toFixed(0) }}
              </td>
              <td>
                <span class="" id="third">{{ day.summary }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment/src/moment";
import { setupCache } from "axios-cache-adapter";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

export default {
  name: "WeatherTableCard",
  props: {},
  data() {
    return {
      daily: [],
      summary: "",
      rain: 0,
      currentTemperature: 0,
      lastUpdated: new Date(),
    };
  },
  methods: {
    mountedSignal() {
      //emitted event back to the parent
      this.$emit("mounted");
    },
  },
  filters: {
    moment(date) {
      return moment(date).startOf("hour").fromNow();
    },
  },
  mounted() {
    // const payload = { lat: "52.5377384", lng: "13.3991115" };
    var path =
      "https://api.intra.io/api/v1/weather?lat=52.5377384&lng=13.3991115";
    const config = {

      method: "GET",
    };
    api
      .get(path, config)
      .then((response) => {
        let responseData = response.data.data;
        let daily = responseData.daily.data;
        this.currentTemperature = responseData.currently.apparentTemperature;
        this.rain = responseData.currently.precipProbability * 100;
        this.summary = responseData.currently.summary;
        this.daily = daily;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  filters: {
    moment(date) {
      return moment.unix(date).format("ddd");
    },
    momentFromNow(date) {
      return moment.utc(date).fromNow();
    },
  },
};
</script>


<style>
</style>
