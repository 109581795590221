<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">{{ currentDate }}</p>
    </header>
    <div class="card-content">
      <div class="content">
        <div>
          <p class="text-gray-600 font-light" id="primary">{{ currentTime }}</p>
          <p class="text-gray-600 font-medium" id="secondary">
            {{ torontoTime }} <span class="font-light" id="third">Toronto</span>
          </p>
          <p class="text-gray-600 font-medium" id="secondary">
            {{ calgaryTime }} <span class="font-light" id="third">Calgary</span>
          </p>
          <p class="text-gray-600 font-medium" id="secondary">
            {{ sydneyTime }}<span class="font-light" id="third"> Sydney</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment/src/moment";

export default {
  name: "CurrentTime",
  data() {
    return {
      title: "today",
      infoTitle: "",
      infoSubTitle: "",
      currentTime: "",
      currentDate: "",
      torontoTime: "",
      calgaryTime: "",
      sydneyTime: "",
      gmtTime: "",
    };
  },
  methods: {
    mountedSignal() {
      //emitted event back to the parent
      this.$emit("mounted");
    },
    time() {
      let date = new Date();
      this.currentTime = date.toLocaleTimeString();
      this.currentDate = date.toDateString();
      let utcTime = new Date().toLocaleString("en-US", {
        timeZone: "UTC",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      let torontoTime = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      let calgaryTime = new Date().toLocaleString("en-US", {
        timeZone: "America/Edmonton",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      let sydneyTime = new Date().toLocaleString("en-US", {
        timeZone: "Australia/Sydney",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      this.torontoTime = torontoTime;
      this.calgaryTime = calgaryTime;
      this.sydneyTime = sydneyTime;
      this.utcTime = utcTime;
    },
  },
  mounted() {
    this.interval = setInterval(this.time, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },

  filters: {
    moment(date) {
      return moment.utc(date).fromNow();
    },
  },
};
</script>
