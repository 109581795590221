<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
}
.media .media-left .image img {
  height: 128px;
}

.section .image img {
  height: 128px;
}
.card-header-title {
  font-family: Prata;
}
a {
  font-family: Prata;
  color: black;
}
#primary {
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  color: #000000;
}
#secondary {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #000000;
}

#third {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  color: #000000;
}
</style>
