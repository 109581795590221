import Vue from 'vue';
import Router from 'vue-router';
import Finance from './views/Finance.vue';
import Health from './views/Health.vue';
import Economy from './views/Economy.vue';
import Home from './views/Home.vue';
import Crypto from './views/Crypto.vue';

Vue.use(Router);


export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/economy',
            name: 'economy',
            component: Economy,
        },
        {
            path: '/finance',
            name: 'finance',
            component: Finance,
        },
        {
            path: '/health',
            name: 'health',
            component: Health,
        },
        {
            path: '/',
            name: 'home',
            component: Home,
        },
        {
            path: '/crypto',
            name: 'crypto',
            component: Crypto,
        }
    ],
});
