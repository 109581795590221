<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">Trading</p>
    </header>
    <div class="card-content">
      <div class="content">
        <table class="">
          <thead>
            <tr>
              <th>Month</th>
              <th>Advice</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(month, index) in response" :key="index">
              <td class="" id="secondary">{{ month.month }}</td>
              <td>
                <span class="" id="third">{{ month.rule }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { setupCache } from "axios-cache-adapter";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

export default {
  name: "TradingCard",
  props: {
    msg: String,
  },
  data() {
    return {
      response: {},
      lastUpdated: new Date(),
    };
  },
  methods: {
    mountedSignal() {
      //emitted event back to the parent
      this.$emit("mounted");
    },
  },
  filters: {
    moment(date) {
      return moment(date).startOf("hour").fromNow();
    },
  },
  mounted() {
    var path = "https://api.intra.io/api/v1/trading/months";
    const config = {
      method: "GET",
    };
    api
      .get(path, config)
      .then((response) => {
        this.response = response.data.data;
        this.lastUpdated = response.data.last_updated;
      })
      .catch((error) => {
        console.log(error);
        this.type = "notification is-danger";
      });
  },
  filters: {
    moment(date) {
      return moment.utc(date).fromNow();
    },
  },
};
</script>


<style>
</style>
