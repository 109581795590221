<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">Vix Index</p>
    </header>
    <div class="card-content">
      <div class="content">
        <apexchart
          type="line"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment/src/moment";
import { setupCache } from "axios-cache-adapter";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

export default {
  name: "VixCard",
  props: {
    msg: String,
  },
  data() {
    return {
      lastUpdated: new Date(),
      chartOptions: {
        chart: {
          id: "vix-index",
          fontFamily: "Poppins",
          toolbar: {
            show: false,
          },
        },
        colors: ["#4BC0C0"],
        xaxis: {
          categories: [],
          type: "datetime",
        },
        yaxis: {
          // tickAmount: 3,
        },
        dataLabels: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
        stroke: {
          width: 2,
          curve: "smooth",
        },
        annotations: {
          yaxis: [
            {
              y: 20,
              borderColor: "#00E396",
              label: {
                borderColor: "#00E396",
                style: {
                  color: "#fff",
                  background: "#00E396",
                },
                text: "Low Stress",
              },
            },
            {
              y: 30,
              borderColor: "#FEB019",
              label: {
                borderColor: "#FEB019",
                style: {
                  color: "#fff",
                  background: "#FEB019",
                },
                text: "Uncertainty",
              },
            },
          ],
        },
      },
      series: [
        {
          name: "Vix Index",
          data: [],
        },
      ],
    };
  },
  methods: {
    mountedSignal() {
      //emitted event back to the parent
      this.$emit("mounted");
    },
  },
  filters: {
    moment(date) {
      return moment(date).startOf("hour").fromNow();
    },
  },
  mounted() {
    var start = moment().subtract(6, "months").format("YYYY-MM-DD");
    var path = "https://api.intra.io/api/v1/vix?start=" + start;
    const config = {
      method: "GET",
    };
    api
      .get(path, config)
      .then((response) => {
        let dates = [];
        let values = [];
        let responseData = response.data.data.observations;

        for (let i of responseData) {
          dates.push(i.date);
          values.push(i.value);
        }

        this.chartOptions = {
          ...this.chartOptions,
          ...{
            labels: dates,
          },
        };
        this.series = [{ data: values }];
      })
      .catch((error) => {
        console.log(error);
        this.type = "notification is-danger";
      });
  },
  filters: {
    moment(date) {
      return moment.utc(date).fromNow();
    },
  },
};
</script>


<style>
</style>
