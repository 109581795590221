<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">Crypto</p>
    </header>
    <div class="card-content">
      <div class="content">
        <table class="">
          <thead>
            <tr>
              <th>Coin</th>
              <th>Price</th>
              <th>% (24h)</th>
              <th>% (7d)</th>
              <th>% (30d)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(coin, index) in data" :key="index">
              <td class="" id="secondary">{{ coin.name }}</td>
              <td class="" id="secondary">
                ${{ coin.quote.USD.price.toFixed(2) }}
              </td>
              <td class="" id="third">
                {{ coin.quote.USD.percent_change_24h.toFixed(0) }}%
              </td>
              <td class="" id="third">
                {{ coin.quote.USD.percent_change_7d.toFixed(0) }}%
              </td>
              <td class="" id="third">
                {{ coin.quote.USD.percent_change_30d.toFixed(0) }}%
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { setupCache } from "axios-cache-adapter";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

export default {
  name: "Crypto",
  props: {
    msg: String,
  },
  data() {
    return {
      lastUpdated: new Date(),
      data: {},
    };
  },
  methods: {
    mountedSignal() {
      //emitted event back to the parent
      this.$emit("mounted");
    },
  },
  mounted() {
    var path = "https://api.intra.io/api/v1/crypto?symbol=btc,eth";
    const config = {

      method: "GET",
    };
    api
      .get(path, config)
      .then((response) => {
        this.data = response.data.data.data;
        this.lastUpdated = response.data.last_updated;
        // this.value = response.data[0].price_usd * this.holdings
      })
      .catch((error) => {
        console.log(error);
        this.type = "notification is-danger";
      });
  },
};
</script>


<style>
</style>
