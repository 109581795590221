<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">Pollen</p>
    </header>
    <div class="card-content">
      <div class="content">
        <div>
          Grass
          <span>
            <progress
              class="progress is-success"
              v-if="count.grass_pollen"
              :value="count.grass_pollen"
              max="342"
            ></progress>
          </span>
        </div>
        <div>
          Tree
          <span>
            <progress
              class="progress is-success"
              v-if="count.tree_pollen"
              :value="count.tree_pollen"
              max="704"
            ></progress>
          </span>
        </div>
        <div>
          Weed
          <span>
            <progress
              class="progress is-success"
              v-if="count.weed_pollen"
              :value="count.weed_pollen"
              max="267"
            ></progress>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment/src/moment";
import { setupCache } from "axios-cache-adapter";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

export default {
  name: "PollenCard",
  props: {},
  data() {
    return {
      count: {},
      lastUpdated: new Date(),
    };
  },
  methods: {
    mountedSignal() {
      //emitted event back to the parent
      this.$emit("mounted");
    },
  },
  filters: {
    moment(date) {
      return moment(date).startOf("hour").fromNow();
    },
  },
  mounted() {
    var path =
      "https://api.intra.io/api/v1/pollen?lat=52.5377384&lng=13.3991115";
    const config = {

      method: "GET",
    };
    api
      .get(path, config)
      .then((response) => {
        let responseData = response.data.data.data;
        this.count = responseData[0].Count;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  filters: {
    moment(date) {
      return moment.unix(date).format("ddd");
    },
    momentFromNow(date) {
      return moment.utc(date).fromNow();
    },
  },
};
</script>


<style>
</style>
