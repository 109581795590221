<template>
  <div class="">
    <top-nav></top-nav>
    <div class="columns">
      <div class="column">
        <div
          class="rounded-2xl border border-gray-200 shadow-xl space-y-2 sm:w-1/3 sm:mx-2 my-1 py-2 px-4"
        >
          <current-time-card v-on:mounted="childMounted"></current-time-card>
        </div>
        <div
          class="rounded-2xl border border-gray-200 shadow-xl space-y-2 sm:w-1/3 sm:mx-2 my-1 py-2 px-4"
        >
          <weather-table-card v-on:mounted="childMounted"></weather-table-card>
          <calendar-card v-on:mounted="childMounted"></calendar-card>
        </div>
      </div>
      <div class="column">
        <div
          class="rounded-2xl border border-gray-200 shadow-xl space-y-2 sm:w-1/3 sm:mx-2 my-1 py-2 px-4"
        >
          <weather-card v-on:mounted="childMounted"></weather-card>
        </div>
      </div>
      <div class="column">
        <div
          class="rounded-2xl border border-gray-200 shadow-xl space-y-2 sm:w-1/3 sm:mx-2 my-1 py-2 px-4"
        ></div>
      </div>
    </div>
  </div>
</template>



<script>
import axios from "axios";
import CurrentTimeCard from "../components/CurrentTimeCard.vue";
import CalendarCard from "@/components/CalendarCard.vue";
import WeatherCard from "../components/WeatherCard.vue";
import WeatherTableCard from "../components/WeatherTableCard.vue";
import TopNav from "../components/TopNav.vue";

export default {
  name: "Home",
  components: {
    CurrentTimeCard,
    CalendarCard,
    WeatherCard,
    WeatherTableCard,
    TopNav,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Home",
    // all titles will be injected into this template
    titleTemplate: "Intra | %s",
  },
  methods: {
    childMounted() {
      //message passed back from child
    },
  },
};
</script>
