<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">Calendar</p>
    </header>
    <div class="card-content">
      <div class="content">
        <p
          v-for="event in events.slice(0, 5)"
          class="text-gray-600 font-medium"
        >
          <span class="title is-6" id="secondary">{{ event.summary }}</span>
          <span class="has-text-right has-text-weight-light" id="third">
            {{ event.start | moment }}</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment/src/moment";
import { setupCache } from "axios-cache-adapter";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

export default {
  name: "CalendarCard",
  props: {
    msg: String,
  },
  data() {
    return {
      events: [],
      lastUpdated: new Date(),
    };
  },
  methods: {
    mountedSignal() {
      //emitted event back to the parent
      this.$emit("mounted");
    },
  },
  filters: {
    moment(date) {
      return moment(date).startOf("hour").fromNow();
    },
  },
  mounted() {
    var path = "https://api.intra.io/api/v1/calendar";
    const config = {

      method: "GET",
    };
    api
      .get(path, config)
      .then((response) => {
        this.events = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        this.type = "notification is-danger";
      });
  },
  filters: {
    moment(date) {
      return moment.utc(date).fromNow();
    },
  },
};
</script>


<style>
</style>
