<template>
  <body font="sans">
    <top-nav></top-nav>
    <div class="columns">
      <div class="column">
        <div class="h-screen bg-gray-100">
          <div class="flex flex-col sm:flex-row">
            <div
              class="rounded-2xl border border-gray-300 shadow-xl space-y-2 sm:w-1/3 sm:mx-2 my-1 py-2 px-4"
            >
              <crypto-fear-card></crypto-fear-card>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="h-screen bg-gray-100">
          <div class="flex flex-col sm:flex-row"></div>
          <div
            class="rounded-2xl border border-gray-300 shadow-xl space-y-2 sm:w-1/3 sm:mx-2 my-1 py-2 px-4"
          >
            <crypto-card></crypto-card>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="h-screen bg-gray-100">
          <div class="flex flex-col sm:flex-row"></div>
          <div
            class="rounded-2xl border border-gray-300 shadow-xl space-y-2 sm:w-1/3 sm:mx-2 my-1 py-2 px-4"
          ></div>
        </div>
      </div>
    </div>
  </body>
</template>



<script>
import axios from "axios";
import TopNav from "../components/TopNav.vue";
import CryptoCard from "../components/CryptoCard.vue";
import CryptoFearCard from "../components/CryptoFearCard.vue";
export default {
  name: "Crypto",
  components: {
    TopNav,
    CryptoCard,
    CryptoFearCard,
  },
  CryptoInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Crypto",
    // all titles will be injected into this template
    titleTemplate: "Intra | %s",
  },
  methods: {
    childMounted() {
      //message passed back from child
    },
  },
};
</script>
