<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
        v-bind:class="{ 'is-active': isHamburgerActive }"
        @click="onclickHamburger"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div
      id="navbarBasicExample"
      class="navbar-menu"
      v-bind:class="{ 'is-active': isHamburgerActive }"
    >
      <div class="navbar-start">
        <a class="navbar-item">
          <router-link to="/"> Home </router-link>
        </a>
        <a class="navbar-item">
          <router-link to="/crypto"> Crypto </router-link>
        </a>
        <a class="navbar-item">
          <router-link to="/finance"> Finance </router-link>
        </a>

        <a class="navbar-item">
          <router-link to="/health"> Health </router-link>
        </a>

        <a class="navbar-item">
          <router-link to="/economy"> Economy </router-link>
        </a>

        <!-- <div class="navbar-item has-dropdown is-hoverable">
                <a class="navbar-link">
                More
                </a>

                <div class="navbar-dropdown">
                    <a class="navbar-item">
                        Health
                    </a>
                </div>
        </div> -->
      </div>

      <div class="navbar-end"></div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "TopNav",
  data() {
    return {
      events: [],
      isHamburgerActive: false,
    };
  },
  methods: {
    onclickHamburger() {
      this.isHamburgerActive = !this.isHamburgerActive;
    },
  },
};
</script>
