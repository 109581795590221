<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">Exchange Rates</p>
    </header>
    <div class="card-content">
      <div class="content">
        <p class="text-gray-600 text-xl" id="secondary">
          {{ rates.EUR.toFixed(2) }}
          <span class="text-gray-600 font-light" id="third"> EURCAD</span>
        </p>

        <p class="text-gray-600 text-xl" id="secondary">
          {{ rates.AUD.toFixed(2)
          }}<span class="text-gray-600 font-light" id="third"> AUDCAD</span>
        </p>

        <p class="text-gray-600 text-xl" id="secondary">
          {{ rates.USD.toFixed(2)
          }}<span class="text-gray-600 font-light" id="third"> USDCAD</span>
        </p>

        <p class="text-gray-600 text-xl" id="secondary">
          {{ rates.GBP.toFixed(2)
          }}<span class="text-gray-600 font-light" id="third"> GBPCAD</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment/src/moment";
import { setupCache } from "axios-cache-adapter";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

export default {
  name: "ExchangeRateCard",
  props: {
    msg: String,
  },
  data() {
    return {
      events: [],
      lastUpdated: new Date(),
      rates: {
        EUR: 0.0,
        AUD: 0.0,
        USD: 0.0,
        GBP: 0.0,
        CAD: 0.0,
      },
    };
  },
  methods: {
    mountedSignal() {
      //emitted event back to the parent
      this.$emit("mounted");
    },
  },
  filters: {
    moment(date) {
      return moment(date).startOf("hour").fromNow();
    },
  },
  mounted() {
    var path = "https://api.intra.io/api/v1/fx?currencies=CAD,AUD,USD,EUR,GBP";
    const config = {

      method: "GET",
    };
    api
      .get(path, config)
      .then((response) => {
        this.rates = response.data.data.rates;
        this.lastUpdated = response.data.last_updated;
      })
      .catch((error) => {
        console.log(error);
        this.type = "notification is-danger";
      });
  },
  filters: {
    moment(date) {
      return moment.utc(date).fromNow();
    },
  },
};
</script>


<style>
</style>
