<template>
  <body font="sans">
    <top-nav></top-nav>
    <div class="columns">
      <div class="column">
        <div class="h-screen bg-gray-100">
          <div class="flex flex-col sm:flex-row">
            <div
              class="
                rounded-2xl
                border border-gray-200
                shadow-xl
                space-y-2
                sm:w-1/3 sm:mx-2
                my-1
                py-2
                px-4
              "
            >
              <covid-card v-on:mounted="childMounted"></covid-card>
              <pollen-card v-on:mounted="childMounted"></pollen-card>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="h-screen bg-gray-100">
          <div class="flex flex-col sm:flex-row">
            <div
              class="
                rounded-2xl
                border border-gray-200
                shadow-xl
                space-y-2
                sm:w-1/3 sm:mx-2
                my-1
                py-2
                px-4
              "
            >
              <body-measurements-card
                measurement="Weight"
                min=150
                max=190
                v-on:mounted="childMounted"
              ></body-measurements-card>
              <body-measurements-card
                measurement="BodyFat"
                min=12
                max=25
                v-on:mounted="childMounted"
              ></body-measurements-card>
            </div>
          </div>
        </div>
      </div>
      <div class="column"></div>
    </div>
  </body>
</template>



<script>
import axios from "axios";
import CovidCard from "../components/CovidCard.vue";
import TopNav from "../components/TopNav.vue";
import PollenCard from "../components/PollenCard.vue";
import BodyMeasurementsCard from "../components/BodyMeasurementsCard.vue";

export default {
  name: "Health",
  components: {
    TopNav,
    CovidCard,
    PollenCard,
    BodyMeasurementsCard,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Health",
    // all titles will be injected into this template
    titleTemplate: "Intra | %s",
  },
  methods: {
    childMounted() {
      //message passed back from child
    },
  },
};
</script>
