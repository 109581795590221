<template>
  <body font="sans">
    <top-nav></top-nav>
    <div class="columns">
      <div class="column">
        <div class="h-screen bg-gray-100">
          <div class="flex flex-col sm:flex-row">
            <div
              class="
                rounded-2xl
                border border-gray-300
                shadow-xl
                space-y-2
                sm:w-1/3 sm:mx-2
                my-1
                py-2
                px-4
              "
            >
              <networth-card v-on:mounted="childMounted"></networth-card>
              <markets-card v-on:mounted="childMounted"></markets-card>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="h-screen bg-gray-100">
          <div class="flex flex-col sm:flex-row"></div>
          <div
            class="
              rounded-2xl
              border border-gray-300
              shadow-xl
              space-y-2
              sm:w-1/3 sm:mx-2
              my-1
              py-2
              px-4
            "
          >
            <exchange-rate-card
              v-on:mounted="childMounted"
            ></exchange-rate-card>
            <trading-card v-on:mounted="childMounted"></trading-card>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="h-screen bg-gray-100">
          <div class="flex flex-col sm:flex-row"></div>
          <div
            class="
              rounded-2xl
              border border-gray-300
              shadow-xl
              space-y-2
              sm:w-1/3 sm:mx-2
              my-1
              py-2
              px-4
            "
          ></div>
        </div>
      </div>
    </div>
  </body>
</template>



<script>
import axios from "axios";
import TopNav from "../components/TopNav.vue";
import NetworthCard from "../components/NetworthCard.vue";
import ExchangeRateCard from "../components/ExchangeRateCard.vue";
import MarketsCard from "../components/MarketsCard.vue";
import TradingCard from "../components/TradingCard.vue";
export default {
  name: "Finance",
  components: {
    TopNav,
    NetworthCard,
    ExchangeRateCard,
    MarketsCard,
    TradingCard,
  },
  FinanceCardInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Finance",
    // all titles will be injected into this template
    titleTemplate: "Intra | %s",
  },
  methods: {
    childMounted() {
      //message passed back from child
    },
  },
};
</script>
