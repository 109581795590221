<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">{{ this.measurement }}</p>
    </header>
    <div class="card-content">
      <div class="content">
        <apexchart
          type="line"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment/src/moment";
import { setupCache } from "axios-cache-adapter";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

export default {
  name: "BodyMeasurementsCard",
  props: {
    msg: String,
    measurement: String,
    min: Number,
    max: Number,
  },
  data() {
    return {
      lastUpdated: new Date(),
      chartOptions: {
        chart: {
          id: "withings-index",
          fontFamily: "Poppins",
          toolbar: {
            show: false,
          },
        },
        colors: ["#00bfe7"],
        xaxis: {
          categories: [],
          type: "datetime",
        },
        dataLabels: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
        stroke: {
          width: 2,
          curve: "smooth",
        },
      },
      series: [
        {
          name: "Body Measurement",
          data: [],
        },
      ],
    };
  },
  methods: {
    mountedSignal() {
      //emitted event back to the parent
      this.$emit("mounted");
    },
  },
  filters: {
    moment(date) {
      return moment(date).startOf("hour").fromNow();
    },
  },
  mounted() {
    var path = "https://api.intra.io/api/v1/" + this.measurement.toLowerCase();
    const config = {
      method: "GET",
    };
    api
      .get(path, config)
      .then((response) => {
        let dates = [];
        let values = [];
        let responseData = response.data.data;
        for (let i of responseData) {
          dates.push(i.Date);
          values.push(i.Value.toFixed(2));
        }

        this.chartOptions = {
          ...this.chartOptions,
          ...{
            labels: dates,
          },
        };
        this.series = [{ data: values }];
      })
      .catch((error) => {
        console.log(error);
        this.type = "notification is-danger";
      });
  },
  filters: {
    moment(date) {
      return moment.utc(date).fromNow();
    },
  },
};
</script>


<style>
</style>
