<template>
  <body font="sans">
    <top-nav></top-nav>
    <div class="columns">
      <div class="column">
        <div class="h-screen bg-gray-100">
          <div class="flex flex-col sm:flex-row">
            <div
              class="rounded-2xl border border-gray-300 shadow-xl space-y-2 sm:w-1/3 sm:mx-2 my-1 py-2 px-4"
            ></div>
            <pe-card></pe-card>
            <buffet-card></buffet-card>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="h-screen bg-gray-100">
          <div class="flex flex-col sm:flex-row"></div>
          <div
            class="rounded-2xl border border-gray-300 shadow-xl space-y-2 sm:w-1/3 sm:mx-2 my-1 py-2 px-4"
          >
            <inflation-card></inflation-card>
            <vix-card></vix-card>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="h-screen bg-gray-100">
          <div class="flex flex-col sm:flex-row"></div>
          <div
            class="rounded-2xl border border-gray-300 shadow-xl space-y-2 sm:w-1/3 sm:mx-2 my-1 py-2 px-4"
          >
            <interest-card></interest-card>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>



<script>
import axios from "axios";
import TopNav from "../components/TopNav.vue";
import InflationCard from "../components/InflationCard.vue";
import InterestCard from "../components/InterestCard.vue";
import PeCard from "../components/PeCard.vue";
import BuffetCard from "../components/BuffetCard.vue";
import VixCard from "../components/VixCard.vue";

export default {
  name: "Finance",
  components: {
    TopNav,
    InflationCard,
    InterestCard,
    PeCard,
    BuffetCard,
    VixCard,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Economy",
    // all titles will be injected into this template
    titleTemplate: "Intra | %s",
  },
  methods: {
    childMounted() {
      //message passed back from child
    },
  },
};
</script>
