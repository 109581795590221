<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">Crypto Fear Index</p>
    </header>
    <div class="card-content">
      <div class="content">
        <p class="text-gray-600 font-medium" id="secondary">
          {{ data.value_classification }}
          <span class="font-light" id="third">{{ data.value }}%</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { setupCache } from "axios-cache-adapter";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api = axios.create({
  adapter: cache.adapter,
});

export default {
  name: "CryptoFearCard",
  props: {
    msg: String,
  },
  data() {
    return {
      lastUpdated: new Date(),
      data: {},
    };
  },
  methods: {
    mountedSignal() {
      //emitted event back to the parent
      this.$emit("mounted");
    },
  },
  mounted() {
    var path = "https://api.alternative.me/fng/?limit=1";
    const config = {

      method: "GET",
    };
    api
      .get(path, config)
      .then((response) => {
        this.data = response.data.data[0];
        // this.value = response.data[0].price_usd * this.holdings
      })
      .catch((error) => {
        console.log(error);
        this.type = "notification is-danger";
      });
  },
};
</script>


<style>
</style>
